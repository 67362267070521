<template>
    <div>
        <table :style='{"width":"100%", "font-size":"7pt", "border-collapse": "collapse", "line-height": "normal",}'>
            <tr>
                <td :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'>
                    Medicamentos
                </td>
                <td :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'>
                    Aplicaciones
                </td>
            </tr>

            <tr
                v-for="itm in datos.medicamentos"
                :key="itm.id"
            >
                <td
                    width="75%"
                    :style="estilos.tablaBorde"
                >
                    <div :style='{"line-height": "normal", "padding":"0px 0px 0px 5px"}'>
                        <div>{{itm.concepto}}</div>
                        <div>{{itm.especificacion}}</div>
                        <div :style='{"font-size":"5pt"}'>{{itm.observaciones}}</div>
                        <div>{{itm.dosis}} {{itm.unidad}} Frec: {{itm.frecuencia}} Via: {{itm.via}}</div>
                    </div>

                </td>
                <td
                    :style="estilos.tablaBorde"
                >
                    <div
                        v-for="itmed in calculaApps(itm.id)"
                        :key="itmed.id"
                    >
                        <div
                            :style='{"float":"left", "padding":"1px", "margin":"1px", "border-radius": "10px", "border": "1px solid  "+$store.state.enfermeria.coloresTuro[turno]}'
                            v-if="!itmed.noAplicado"
                        >
                            <div>{{itmed.hora}}</div>
                            <div v-if="itmed.dilu" :style='{"font-size":"5pt", "text-align":"center"}'>{{itmed.dilu}} ml</div>
                        </div>

                        <div
                            :style='{"float":"left", "padding":"1px", "margin":"1px"}'
                            v-if="itmed.noAplicado"
                        >
                            {{itmed.hora}}
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

    methods:{
        calculaApps(med){
            let medsApps = this.datos.aplicados.filter(itm=>itm.medicamento==med)
            //console.log(medsApps)
            return medsApps
        }
    },

}
</script>

<style>

</style>